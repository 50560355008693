<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenService.indent_received') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="">
              <b-form-group
                label-for="fiscal_year_id"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year') }}
                </template>
                <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Tea Company" vid="company_id" rules="">
              <b-form-group
                label-for="company_id"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{ $t('teaGardenConfig.tea_company') }}
                </template>
                <v-select
                  id="company_id"
                  v-model="search.company_id"
                  :reduce="op => op.value"
                  :options="masterTeaCompanyList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :placeholder="$t('globalTrans.select')"
                >
                </v-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Tea Garden" vid="garden_id" rules="">
              <b-form-group
                label-for="garden_id"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{ $t('teaGardenConfig.tea_garden_name') }}
                </template>
                <v-select
                  id="garden_id"
                  v-model="search.garden_id"
                  :reduce="op => op.value"
                  :options="gardenList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :placeholder="$t('globalTrans.select')"
                >
                </v-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12" class="mt-3">
            <b-button size="sm" variant="primary" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenService.indent_received') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button v-if="multipleForwardIds.length > 0" class="moc-action-btn moc-forward-btn" :title="$t('globalTrans.forward')" v-b-modal.modal-multiple-forward>
          <i class="ri-arrow-go-forward-fill"></i>
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper justify-content-end">
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="search.limit"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table-simple hover small bordered responsive>
                <b-thead>
                  <b-tr>
                     <b-th class="text-center" rowspan="2">
                        <b-form-checkbox @change="selectAllForwardData" v-model="select_all" id="select_id"> {{ $t('globalTrans.checkAll') }}</b-form-checkbox>
                    </b-th>
                    <b-th rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th rowspan="2">{{ $t('globalTrans.fiscal_year') }}</b-th>
                    <b-th rowspan="2">{{ $t('teaGardenConfig.tea_garden_name') }}</b-th>
                    <b-th rowspan="2">{{ $t('teaGardenConfig.total_land') }}</b-th>
                    <b-th rowspan="2">{{ $t('teaGarden.total_cultivate_land') }}</b-th>
                    <b-th :colspan="masterFertilizerList.length">{{ $t('teaGarden.req_fer_qty') }}</b-th>
                    <b-th rowspan="2">{{ $t('globalTrans.action') }}</b-th>
                  </b-tr>
                  <b-tr v-if="masterFertilizerList.length">
                    <b-th v-for="(item, index) in masterFertilizerList" :key="'fer-' + index">
                      {{ currentLocale === 'en' ? item.text_en: item.text_bn }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="listData.length">
                    <b-tr v-for="(item, index) in listData" :key="index">
                      <b-td class="text-center">
                          <b-form-checkbox v-if="item.status === 3" v-model="multipleForwardIds" :value="item.id"></b-form-checkbox>
                      </b-td>
                      <b-td>{{ $n(index + pagination.slOffset) }}</b-td>
                      <b-td>{{ getFiscalYearName(item.fiscal_year_id) }}</b-td>
                      <b-td>{{ getGardenName(item.garden_id) }}</b-td>
                      <b-td>{{ $n(item.total_land) }}</b-td>
                      <b-td>{{ $n(item.total_cultivate_land) }}</b-td>
                      <b-td v-for="(fertilizerItem, fertilizerIndex) in item.proposals" :key="'fer-pro-' + fertilizerIndex">
                        {{ $n(fertilizerItem.reqst_qty) }}
                      </b-td>
                      <b-td>
                        <router-link class="moc-action-btn moc-view-btn" :to="`indent-submissions-view/${item.id}`" :title="$t('globalTrans.view')">
                          <i class="ri-eye-line"></i>
                        </router-link>
                        <b-button class="moc-action-btn moc-forward-btn" :title="$t('globalTrans.forward')" v-b-modal.modal-5 @click="setItem(item)">
                          <i class="ri-arrow-go-forward-fill"></i>
                        </b-button>
                      </b-td>
                    </b-tr>
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td :colspan="7 + masterFertilizerList.length" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
              <div class="pagination-wrapper mt-3">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-5" size="lg" :title="$t('globalTrans.forward')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" hide-footer>
      <Forward :id="item.id" hide-footer/>
    </b-modal>
    <b-modal id="modal-multiple-forward" size="lg" :title="$t('globalTrans.forward')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" hide-footer>
      <MultipleForward :app_ids="multipleForwardIds" hide-footer/>
    </b-modal>
  </div>
</template>
<style scoped>
table#table-transition-example .flip-list-move {
  transition: transform 1s;
}
</style>
<script>

import
  RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { fertilizerIndentReceivedList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
import Forward from './Forward'
import MultipleForward from './MultipleForward'
export default {
  mixins: [ModalBaseMasterList],
  components: { Forward, MultipleForward },
  data () {
    return {
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      search: {
        circular_id: this.$route.query.circular_id ? this.$route.query.circular_id : 0,
        fiscal_year_id: 0,
        company_id: null,
        garden_id: null,
        status: 0,
        limit: 20
      },
      districtList: [],
      upazilaList: [],
      corporationList: [],
      unionList: [],
      pauroshobaList: [],
      labelData: [],
      gardenList: [],
      editItemId: 0,
      today: new Date().toISOString().split('T')[0],
      item: {
        id: 0
      },
      multipleForwardIds: [],
      select_all: false
    }
  },
  created () {
    this.labelData = this.labelList
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.company_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.gardenList = this.getGardenList(newVal)
      } else {
        this.gardenList = []
      }
    },
    currentLocale: function (newVal) {
      this.gardenList = this.gardenList.map(item => {
        return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
      })
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions.map(item => {
        const customItem = {
          text: this.currentLocale === 'en' ? item.text : item.text_bn
        }
        return Object.assign({}, item, customItem)
      })
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    masterTeaCompanyList: function () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaCompanyList.filter(item => item.status === 1)
    },
    masterTeaGardenGenInfoList: function () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    masterFertilizerList () {
      return this.$store.state.TeaGardenService.commonObj.masterFertilizerList.filter(item => item.status === 1)
    },
    statusList () {
      const arr = [
        { value: 1, text: this.$t('globalTrans.draft'), text_en: this.$t('globalTrans.draft', 'en'), text_bn: this.$t('globalTrans.draft', 'bn') },
        { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
        { value: 3, text: this.$t('teaGardenBtriService.assigned'), text_en: this.$t('teaGardenBtriService.assigned', 'en'), text_bn: this.$t('teaGardenBtriService.assigned', 'bn') },
        { value: 4, text: this.$t('globalTrans.forward'), text_en: this.$t('globalTrans.forward', 'en'), text_bn: this.$t('globalTrans.forward', 'bn') },
        { value: 5, text: this.$t('globalTrans.verified'), text_en: this.$t('globalTrans.verified', 'en'), text_bn: this.$t('globalTrans.verified', 'bn') },
        { value: 6, text: this.$t('globalTrans.processing'), text_en: this.$t('globalTrans.processing', 'en'), text_bn: this.$t('globalTrans.processing', 'bn') },
        { value: 10, text: this.$t('globalTrans.approved'), text_en: this.$t('globalTrans.approved', 'en'), text_bn: this.$t('globalTrans.approved', 'bn') }
      ]
      return arr
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, fertilizerIndentReceivedList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const garden = this.masterTeaGardenGenInfoList.find(obj => obj.value === parseInt(item.garden_id))
          const customItem = {
            total_land: garden.total_land
          }
            return Object.assign({ serial: index }, item, customItem)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    setItem (item) {
      this.item = item
    },
    getGardenList (id) {
      return this.masterTeaGardenGenInfoList.filter(item => item.tea_company_id === parseInt(id))
    },
    getFiscalYearName (id) {
      const obj = this.fiscalYearList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getGardenName (id) {
      const obj = this.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    selectAllForwardData () {
      if (this.select_all) {
          this.listData.forEach(item => {
              if (item.status === 3) {
                  this.multipleForwardIds.push(item.id)
              }
          })
      } else {
          this.multipleForwardIds = []
      }
    }
  }
}
</script>
<style>
.blinking {
  animation: 1s blink ease infinite;
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
</style>
